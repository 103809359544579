#after-save-loading .loader {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 9999;
	text-align: center;
	background-color: #f3f3f3;
	opacity: 0.5;
}

#after-save-loading .loader-icon {
  	border: 8px solid #bbb;
  	border-top: 8px solid #339933;
  	border-radius: 50%;
  	width: 50px;
  	height: 50px;
  	animation: spin 1.5s linear infinite;
  	position: fixed;
  	z-index: 99999;
  	top: 40%;
  	left: 46%;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
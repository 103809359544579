@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-dpg-table table, .rui-dpg-table thead, .rui-dpg-table tbody, .rui-dpg-table th, .rui-dpg-table td, .rui-dpg-table tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-dpg-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-dpg-table tr {
    margin: 0;
  }
    
  .rui-dpg-table tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-dpg-table td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-dpg-table td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-dpg-table td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-dpg-table td:nth-of-type(2):before { content: "ชื่อกลุ่ม"; font-weight: bold; }
  .rui-dpg-table td:nth-of-type(3):before { content: "คนภายในกลุ่ม"; font-weight: bold; }
  .rui-dpg-table td:nth-of-type(4):before { content: "จำนวน(คน)"; font-weight: bold; }
}
#DocumentTemplatePage .document-card {
	font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
    color: #354368;
    height: 200px;
    padding-top: 60px;
    cursor: pointer;
    -webkit-box-shadow: -7px 7px 10px 2px rgba(0, 0, 0, .3);
    box-shadow: -7px 7px 10px 2px rgba(0, 0, 0, .3);
    text-shadow: -3px 3px 6px #858585;
}

#DocumentTemplatePage .note {
    position: relative;
    background-color: #f1f1f1;
    background-repeat: no-repeat;
    background-position-y: 20px;
    background-position-x: 10px;
    background-size: 35px;
    -webkit-background-size: 35px;
    -moz-background-size: 35px;
    -o-background-size: 35px;
    overflow: hidden;
}

#DocumentTemplatePage .note:before {
	content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 16px 16px 0;
    border-style: solid;
    border-color: #fff #fff rgba(0,0,0,0.2) rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.2);
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
    box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
    display: block;
    width: 0;
}
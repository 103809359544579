#secret {
  background-color: #eeb9b9; 
}

#light .navbar-toggler {
  background-color: black; 
}

#light a {
  color: #007bff;
}

#light .text-link {
  color: #007bff;
}

#light a.btn-danger {
  color: white;
}

#light .header__top {
  background-color: #abe8c8; 
}

#light #LoginPage {  
    background-color: #abe8c8;
    padding-top: 15px;
    padding-bottom: 50px;
}

#light .header__top.navbar-dark .navbar-nav .nav-link {
  color: #808080;
}

#light .header__top.navbar-dark .navbar-nav .nav-link.active {
  color: #000000;
  font-weight: 600;
  text-decoration: underline;
}

#light .nav.nav-tabs .nav-item .nav-link {
  color: #808080;
}

#light .nav.nav-tabs .nav-item .nav-link.active {
  font-weight: bold;
  color: #007bff;
}

#light .nav-pills .nav-link.active, 
#light .show > .nav-pills .nav-link {
  background-color: gray;
  border-radius: 0;
  color: #ffffff;
}

#light .btn-success {
  background-color: #fe6227;
  border: #fe6227;
  color: #ffffff;
  border-radius: 0;
}

#light .btn {
  border-radius: 0;
  color: #ffffff;
}

#light .btn-link {
  border-radius: 0;
  color: #007bff;
}

#light .page-link {
  color: #000 !important;
  border: 1px solid #aaa;
}

.next-number {
  color: red;
  font-size: 34px;
  font-weight: bold;
  background-color: yellow;
  padding: 0 15px;
}

.wait-for-receive {
  color: red;
  font-weight: bold;
}

.btn-notice {  
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
html, body {
	width: 100%;
	height: 100%;
	background-color: #abe8c8;
	font-size: 1.15rem;
	font-family: "THSarabunNew";
	line-height: 1;
	font-weight: 800;
}

#root {
	height: 100%;
}

#root>div {
	height: 100%;
}

h1, .h1 {
	font-size: 2.4rem;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	font-weight: 800;
}

input {
	font-weight: 800;
}

input.form-control, select.form-control {
	height: 1.6rem;
}

#AppLayout {
	overflow-x: hidden;
	box-sizing: border-box;
}

.cursor-pointer{
  cursor: pointer;
}

.inbox-body {
	/*min-height: 550px;*/
	min-height: 78vh;
}

.react-datepicker-popper {
	z-index: 9;
}

.nav-link {
	padding: 0.35rem 0.25rem;
}

.nav > .nav-item {
	border-bottom: 0.5px;
	border-bottom-color: lightgray; 
	border-bottom-style: dashed;
}

.nav > .nav-item > .nav-link {
	color: inherit;
}

.nav > .nav-item > .nav-link:hover {
	font-weight: bold;
	text-decoration: underline;
}

.nav-pills .nav-link.active, .show > .nav-pills .nav-link {
	color: inherit;
	font-weight: bold;
	background-color: #9BD3EE;
	text-decoration: underline;
}

.text_underline {
	text-decoration: underline;
}

.edoc-logo-sm {
	width: 100%;
	height: auto;
}

.field-label {
	margin-bottom: 0;
}

.required::after {
  content: ' * ';
  color: red;
}

.pointer {
	cursor: pointer;;
}

.nav > .nav-item {
	border-bottom: 1px dashed lightgray;
}

.modal-sm {
	max-width: 600px !important;
}

.a4-paper {
	width: 210mm;
	height: 297mm;
	padding-top: 25mm;
	padding-left: 30mm;
	padding-right: 20mm;
	font-size: 16pt;
	position: relative;
	margin: 0 auto;
}

.footer-page {
	position: absolute;
  bottom: 20mm;
  border: 1px dashed orange;
  width: 77%;
  left: 30mm;
}

.a4-vertical-padding {
	padding: 3rem;
}

.delete-document-page-btn {
	position: absolute;
	top: 2mm;
	right: 24mm;
}

.emblem-center {
	width: 14%;
	position: absolute;
	left: 43%;
}

.garuda-emblem-logo {
	height: 80px;
}

.green-icon {
	color: green;
}

.green-text {
	color: green;
}

.red-icon {
	color: #cc0000;
}

.blue-icon {
	color: cadetblue;
}

.gray-icon {
	color: gray;
}

.gray-icon:hover {
	color: #222;
}

.black-icon {
	color: black;
}

.text-black {
	color: black;
}

.text-black:hover {
	color: black;
}

.focus-item {
	background: lightblue;
}

.group-list {
	line-height: 30px;
}

.group-list:hover {
	background: lightblue;
}

.group-content {
	height: 100vh;
	overflow-y: scroll; 
}

.children-content {
	height: 380px;
	overflow-y: scroll; 
}

.react-bs-select-all {
	display: none;
}

#organize-header {
	height: 50px;
}

.ul-group-list {
	list-style-type: none;
	padding-left: 20px;
}

.react-bs-table-no-data {
	color: gray;
}

.nav.nav-tabs .nav-item .nav-link {
	color: #08c;
}

.nav.nav-tabs .nav-item .nav-link.active {
	color: gray;
}

.nav.nav-tabs {
	margin-left: 0;
}

.top-right {
	position: relative;
	float: right;
}

.inbox {
	font-size: 1.1rem;
}

.small {
	font-size: 1.2rem;
}

.xs-small {
	font-size: 1rem;
}

.xxs-small {
	font-size: 0.9rem;
}

.btn {
  padding: .4rem .5rem .25rem;
  font-size: 1.2rem;
  line-height: 0.8;
}

hr {
	margin: 0;
}

.form-control {
	padding: 1px 0.5rem 1px 0.5rem;
	line-height: 1;
	font-size: 1.15rem;
}

.fa {
	font-size: 0.85rem;
}

.fa-2x {
	font-size: 1rem;
}

.mini-icon {
	font-size: 0.3rem;
}

.small-icon {
	font-size: 0.6rem;
}

select.form-control.form-control-sm {
  font-family: Sans-Serif;
	font-size: 0.8rem;
}

.text-overflow {
  	overflow:hidden;
  	display:inline-block;
  	text-overflow: ellipsis;
  	white-space: nowrap;
}

.small-btn {
	font-size: 1rem;
    line-height: 0.5;
}

.popover-header {
	font-size: 16px;
}

.popover-body {
	font-size: 14px;
}

p {
	margin-bottom: 0;
	line-height: 1.1;
}

.ex-small-text {
	font-size: 14px;
}

.purple-text {
	color: #57E;
}

.orange-text {
	color: #F90;
}

.list-style-none {
	list-style-type: none;
}

.list-thai-style {
	list-style-type: thai;
}

.green-border {
	border: 1px solid green;
}

.popover-list > li {
	background-color: #eee; 
	font-size: 0.8rem;
	list-style-type: none;
	cursor: pointer;
}

.popover-list > li:hover {
	background-color: rgba(128,201,255,.7);
}

.close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.position-relative {
	position: relative;
}

table tr.pointer {
  cursor: pointer;
}

.page-link {
	line-height: 0.7;
}

.loading-color {
	color: #08c;
}

.lightgray_hover:hover {
    background-color: lightgray !important;
}

.overflow-visible {
	overflow: visible !important;
	white-space: normal !important;
}

.urgent {
	background-color: #FADBD8 !important;
}

.very-hurry {
	background-color: #FAE5D3 !important;
}

.hurry {
	background-color: #FEF5E7 !important;
}

.speed-level-hurry.active, .secret-level-confidential.active {
	background-color: #FEF5E7 !important;
	border-color: #FEF5E7 !important;
	color: #212529 !important;
}

.speed-level-very_hurry.active, .secret-level-secret.active {
	background-color: #FAE5D3 !important;
	border-color: #FAE5D3 !important;
	color: #212529 !important;
}

.speed-level-urgent.active, .secret-level-top_secret.active {
	background-color: #FADBD8 !important;
	border-color: #FADBD8 !important;
	color: #212529 !important;
}

.text-hurry, .text-very_hurry, .text-urgent {
	color: red;
	font-weight: bold;
	font-size: 1.1rem;
}

.unread {
	background-color: #FFFACD !important;
}

.error_message_navigation {
	height: 70px;
}

.react-bs-table {
	font-size: 1.1rem;
}

.label-control {
	font-weight: bold;
	margin-bottom: 0;
}

.Select-placeholder, .Select--single > .Select-control .Select-value {
	line-height: 44px;
}

legend {
	width: auto;
}

.bg-lightblue {
	background: #5e9ac6;
}

.text-md {
	font-size: 16pt;
}

.ml-minus-15 {
	margin-left: -15px;
}

.ml-minus-20 {
	margin-left: -20px;
}

.ml-minus-25 {
	margin-left: -25px;
}

.ml-minus-30 {
	margin-left: -30px;
}

.ml-minus-35 {
	margin-left: -35px;
}

.text_underline_dashed {
	line-height: 1.1;
  background: url("assets/images/DotForType1.png");
}

.react-bs-table input[type='checkbox']:disabled {
	display: none;
}

.react-bs-table-search-form input {
	height: 35px;
}

textarea.form-control {
	padding: 0.5rem;
}

.speed_level-list > label, .secret_level-list > label {
	width: 100px;
}

.btn-group-vertical>.btn.active, 
.btn-group-vertical>.btn:active, 
.btn-group-vertical>.btn:focus, 
.btn-group>.btn.active, 
.btn-group>.btn:active, 
.btn-group>.btn:focus {
	z-index: 0;
}

ul.ul-target-list {
	margin: 0;
	padding: 0 0 0 1rem;
}

.table-bordered tr, .table-bordered td, .table-bordered th, .table-bordered thead th {
	border: 1px solid darkgray;
}

.indent {
	padding-left: 2rem;
}

/* react autosuggest*/
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 30px;
  padding: 10px 10px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

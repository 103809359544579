.forum-content {
  white-space: pre-wrap;
  tab-size: 16;
}

.forum-content p {
  padding-top: 6pt;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

ol, ul {
  margin-left: 1.5rem;
  margin-bottom: 0;
}

.forums a {
  color: #343a40 !important;
}
.settings-page .card-box-shadow {
  box-shadow: 0 2px 6px 0 lightgray;
}

.settings-page .darkgreen-border-top {
  border-top: 6px solid darkgreen;
}

.settings-page .darkblue-border-top {
  border-top: 6px solid darkblue;
}

.settings-page .darkred-border-top {
  border-top: 6px solid darkred;
}

.settings-page .darkgoldenrod-border-top {
  border-top: 6px solid darkgoldenrod;
}

.settings-page .darkcyan-border-top {
  border-top: 6px solid darkcyan;
}
.class-level {
	color: red;
}

.page-header {
	margin-top: 21mm;
}

.show-department-name-seal-document {
  position: absolute;
  top: 50%;
  width: 95%;
}

.fixed-top-right {
  position: absolute;
  top: 5px;
  right: 5px;
}

.space-between-signature {
  margin-top: 48pt;
}
#SettingUserPage .nav.nav-tabs {
    float: left;
    display: block;
    margin-right: 20px;
    border-bottom:0;
    border-right: 1px solid #ddd;
    padding-right: 15px;
}

#SettingUserPage li.active {
	font-weight: bold;
}

#SettingUserPage .nav-tabs li:not(.active):hover {
	font-weight: 500;
	color: gray;
}
.ql-container {
  font-family: "THSarabunNew";
}

.quill, #toolbar, .raw-html {
  margin-left: -15px;
  margin-right: -15px;
}

#editing_attachment_id .quill {
  margin-left: -15px;
  margin-right: -15px;
}

.quill p, .editing_content p, .editing_content li {
  white-space: pre-wrap;
  line-height: 1.1;
}

.ql-editor, .editing_content {
  tab-size: 20;
  -moz-tab-size: 20;
  -o-tab-size:  20;
}

.ql-editor {
  font-size: 16pt;
}

.ql-editor p:not(:first-child), 
.ql-editor ol:not(:first-child), 
.ql-editor ul:not(:first-child), 
.ql-editor pre:not(:first-child), 
.ql-editor blockquote:not(:first-child), 
.ql-editor h1:not(:first-child), 
.ql-editor h2:not(:first-child), 
.ql-editor h3:not(:first-child), 
.ql-editor h4:not(:first-child), 
.ql-editor h5:not(:first-child), 
.ql-editor h6:not(:first-child) {
  padding-top: 6pt;
}

.editing_content p:not(:first-child), 
.editing_content ol:not(:first-child), 
.editing_content ul:not(:first-child), 
.editing_content pre:not(:first-child), 
.editing_content blockquote:not(:first-child), 
.editing_content h1:not(:first-child), 
.editing_content h2:not(:first-child), 
.editing_content h3:not(:first-child), 
.editing_content h4:not(:first-child), 
.editing_content h5:not(:first-child), 
.editing_content h6:not(:first-child) {
  padding-top: 6pt;
}

.editing_content ol {
  margin-bottom: 0;
}

.attached_things_label p, .suggest_text_label p {
  padding-top: 0;
}

.raw-editor {
  border-top: 0;
}

.raw-editor { display: none; width: 100%; }
.showRaw .ql-editor  { display: none; }
.showRaw .raw-editor { display: block; }

#toolbar .ql-html {
  font-size: 15px;
  font-weight: normal;
  width: 60px;
}

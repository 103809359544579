
#secret {
  background-color: #eeb9b9; 
}

#light .navbar-toggler {
  background-color: black; 
}

#light a {
  color: #007bff;
}

#light .text-link {
  color: #007bff;
}

#light a.btn-danger {
  color: white;
}

#light .header__top {
  background-color: #abe8c8; 
}

#light #LoginPage {  
    background-color: #abe8c8;
    padding-top: 15px;
    padding-bottom: 50px;
}

#light .header__top.navbar-dark .navbar-nav .nav-link {
  color: #808080;
}

#light .header__top.navbar-dark .navbar-nav .nav-link.active {
  color: #000000;
  font-weight: 600;
  text-decoration: underline;
}

#light .nav.nav-tabs .nav-item .nav-link {
  color: #808080;
}

#light .nav.nav-tabs .nav-item .nav-link.active {
  font-weight: bold;
  color: #007bff;
}

#light .nav-pills .nav-link.active, 
#light .show > .nav-pills .nav-link {
  background-color: gray;
  border-radius: 0;
  color: #ffffff;
}

#light .btn-success {
  background-color: #fe6227;
  border: #fe6227;
  color: #ffffff;
  border-radius: 0;
}

#light .btn {
  border-radius: 0;
  color: #ffffff;
}

#light .btn-link {
  border-radius: 0;
  color: #007bff;
}

#light .page-link {
  color: #000 !important;
  border: 1px solid #aaa;
}

.next-number {
  color: red;
  font-size: 34px;
  font-weight: bold;
  background-color: yellow;
  padding: 0 15px;
}

.wait-for-receive {
  color: red;
  font-weight: bold;
}

.btn-notice {  
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
#secret {
  background-color: #eeb9b9; 
}

#secret .navbar-toggler {
  background-color: black; 
}

#secret a {
  color: #007bff;
}

#secret .text-link {
  color: #007bff;
}

#secret a.btn-danger {
  color: white;
}

#secret a.btn.switch-service-button {
  background-color: #abe8c8; 
  border-color: #abe8c8; 
  color: #495057;
}

#secret .header__top {
  background-color: #eeb9b9; 
}

#secret .header__top.navbar-dark .navbar-nav .nav-link {
  color: #808080;
}

#secret .header__top.navbar-dark .navbar-nav .nav-link.active {
  color: #000000;
  font-weight: 600;
  text-decoration: underline;
}

#secret .nav.nav-tabs .nav-item .nav-link {
  color: #808080;
}

#secret .nav.nav-tabs .nav-item .nav-link.active {
  font-weight: bold;
  color: #007bff;
}

#secret .nav-pills .nav-link.active, 
#secret .show > .nav-pills .nav-link {
  background-color: gray;
  border-radius: 0;
  color: #ffffff;
}

#secret .btn-success {
  background-color: #fe6227;
  border: #fe6227;
  color: #ffffff;
  border-radius: 0;
}

#secret .btn {
  border-radius: 0;
  color: #ffffff;
}

#secret .btn-link {
  border-radius: 0;
  color: #007bff;
}

#secret .page-link {
  color: #000 !important;
  border: 1px solid #aaa;
}

.next-number {
  color: red;
  font-size: 34px;
  font-weight: bold;
  background-color: yellow;
  padding: 0 15px;
}

.wait-for-receive {
  color: red;
  font-weight: bold;
}

.btn-notice {  
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.loading {
	text-align: left;
}

.loading:after {
	color: #08c;
  	overflow: hidden;
  	display: inline-block;
  	vertical-align: bottom;
  	-webkit-animation: ellipsis steps(5,end) 900ms infinite;      
  	animation: ellipsis steps(5,end) 900ms infinite;
  	content: "\2026"; /* ascii code for the ellipsis character */
  	width: 0px;
}

@keyframes ellipsis {
  	to {
    	width: 30px;    
  	}
}

@-webkit-keyframes ellipsis {
  	to {
   		width: 30px;    
  	}
}

/*
#fountainG{
	position:relative;
	width:234px;
	height:4px;
}

.fountainG{
	position:absolute;
	top:0;
	background-color: #08c;
	width:15px;
	height:15px;
	animation-name:bounce_fountainG;
		-o-animation-name:bounce_fountainG;
		-ms-animation-name:bounce_fountainG;
		-webkit-animation-name:bounce_fountainG;
		-moz-animation-name:bounce_fountainG;
	animation-duration:1.5s;
		-o-animation-duration:1.5s;
		-ms-animation-duration:1.5s;
		-webkit-animation-duration:1.5s;
		-moz-animation-duration:1.5s;
	animation-iteration-count:infinite;
		-o-animation-iteration-count:infinite;
		-ms-animation-iteration-count:infinite;
		-webkit-animation-iteration-count:infinite;
		-moz-animation-iteration-count:infinite;
	animation-direction:normal;
		-o-animation-direction:normal;
		-ms-animation-direction:normal;
		-webkit-animation-direction:normal;
		-moz-animation-direction:normal;
	transform:scale(.3);
		-o-transform:scale(.3);
		-ms-transform:scale(.3);
		-webkit-transform:scale(.3);
		-moz-transform:scale(.3);
	border-radius:19px;
		-o-border-radius:19px;
		-ms-border-radius:19px;
		-webkit-border-radius:19px;
		-moz-border-radius:19px;
}

#fountainG_1{
	left:0;
	animation-delay:0.6s;
		-o-animation-delay:0.6s;
		-ms-animation-delay:0.6s;
		-webkit-animation-delay:0.6s;
		-moz-animation-delay:0.6s;
}

#fountainG_2{
	left:29px;
	animation-delay:0.75s;
		-o-animation-delay:0.75s;
		-ms-animation-delay:0.75s;
		-webkit-animation-delay:0.75s;
		-moz-animation-delay:0.75s;
}

#fountainG_3{
	left:58px;
	animation-delay:0.9s;
		-o-animation-delay:0.9s;
		-ms-animation-delay:0.9s;
		-webkit-animation-delay:0.9s;
		-moz-animation-delay:0.9s;
}

#fountainG_4{
	left:88px;
	animation-delay:1.05s;
		-o-animation-delay:1.05s;
		-ms-animation-delay:1.05s;
		-webkit-animation-delay:1.05s;
		-moz-animation-delay:1.05s;
}

#fountainG_5{
	left:117px;
	animation-delay:1.2s;
		-o-animation-delay:1.2s;
		-ms-animation-delay:1.2s;
		-webkit-animation-delay:1.2s;
		-moz-animation-delay:1.2s;
}

#fountainG_6{
	left:146px;
	animation-delay:1.35s;
		-o-animation-delay:1.35s;
		-ms-animation-delay:1.35s;
		-webkit-animation-delay:1.35s;
		-moz-animation-delay:1.35s;
}

#fountainG_7{
	left:175px;
	animation-delay:1.5s;
		-o-animation-delay:1.5s;
		-ms-animation-delay:1.5s;
		-webkit-animation-delay:1.5s;
		-moz-animation-delay:1.5s;
}

#fountainG_8{
	left:205px;
	animation-delay:1.64s;
		-o-animation-delay:1.64s;
		-ms-animation-delay:1.64s;
		-webkit-animation-delay:1.64s;
		-moz-animation-delay:1.64s;
}



@keyframes bounce_fountainG{
	0%{
	transform:scale(1);
		background-color: #08c;
	}

	100%{
	transform:scale(.3);
		background-color:rgb(255,255,255);
	}
}

@-o-keyframes bounce_fountainG{
	0%{
	-o-transform:scale(1);
		background-color: #08c;
	}

	100%{
	-o-transform:scale(.3);
		background-color:rgb(255,255,255);
	}
}

@-ms-keyframes bounce_fountainG{
	0%{
	-ms-transform:scale(1);
		background-color: #08c;
	}

	100%{
	-ms-transform:scale(.3);
		background-color:rgb(255,255,255);
	}
}

@-webkit-keyframes bounce_fountainG{
	0%{
	-webkit-transform:scale(1);
		background-color: #08c;
	}

	100%{
	-webkit-transform:scale(.3);
		background-color:rgb(255,255,255);
	}
}

@-moz-keyframes bounce_fountainG{
	0%{
	-moz-transform:scale(1);
		background-color: #08c;
	}

	100%{
	-moz-transform:scale(.3);
		background-color:rgb(255,255,255);
	}
}*/
#after-save-loading .loader {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 9999;
	text-align: center;
	background-color: #f3f3f3;
	opacity: 0.5;
}

#after-save-loading .loader-icon {
  	border: 8px solid #bbb;
  	border-top: 8px solid #339933;
  	border-radius: 50%;
  	width: 50px;
  	height: 50px;
  	-webkit-animation: spin 1.5s linear infinite;
  	        animation: spin 1.5s linear infinite;
  	position: fixed;
  	z-index: 99999;
  	top: 40%;
  	left: 46%;
}

@-webkit-keyframes spin {
	0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
	0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.a4-paper #show_content_id .editing_content {
  text-align: justify;
}
.class-level {
	color: red;
}

.page-header {
	margin-top: 21mm;
}

.show-department-name-seal-document {
  position: absolute;
  top: 50%;
  width: 95%;
}

.fixed-top-right {
  position: absolute;
  top: 5px;
  right: 5px;
}

.space-between-signature {
  margin-top: 48pt;
}

@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-positions-user-tb table, .rui-positions-user-tb thead, .rui-positions-user-tb tbody, .rui-positions-user-tb th, .rui-positions-user-tb td, .rui-positions-user-tb tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-positions-user-tb thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-positions-user-tb tr {
    margin: 0;
  }
    
  .rui-positions-user-tb tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-positions-user-tb td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-positions-user-tb td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-positions-user-tb td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-positions-user-tb td:nth-of-type(2):before { content: "\E0A\E37\E48\E2D-\E19\E32\E21\E2A\E01\E38\E25"; font-weight: bold; }
  .rui-positions-user-tb td:nth-of-type(3):before { content: "\E15\E33\E41\E2B\E19\E48\E07"; font-weight: bold; }
  .rui-positions-user-tb td:nth-of-type(4):before { content: "\E41\E1C\E19\E01"; font-weight: bold; }
}
#workflow-actions-order button.active {
  background: darkgreen;
}
.ReactPDF__Page__canvas {
	margin: 0 auto;
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-issue-number-tb table, .rui-issue-number-tb thead, .rui-issue-number-tb tbody, .rui-issue-number-tb th, .rui-issue-number-tb td, .rui-issue-number-tb tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-issue-number-tb thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-issue-number-tb tr {
    margin: 0;
  }
    
  .rui-issue-number-tb tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-issue-number-tb td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-issue-number-tb td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-issue-number-tb td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-issue-number-tb td:nth-of-type(2):before { content: "\E17\E35\E48"; font-weight: bold; }
  .rui-issue-number-tb td:nth-of-type(3):before { content: "\E40\E2D\E01\E2A\E32\E23\E25\E07\E27\E31\E19\E17\E35\E48"; font-weight: bold; }
  .rui-issue-number-tb td:nth-of-type(4):before { content: "\E40\E23\E35\E22\E19"; font-weight: bold; }
  .rui-issue-number-tb td:nth-of-type(5):before { content: "\E40\E23\E37\E48\E2D\E07"; font-weight: bold; }
  .rui-issue-number-tb td:nth-of-type(6):before { content: "\E08\E32\E01"; font-weight: bold; }
  .rui-issue-number-tb td:nth-of-type(7):before { content: "\E16\E36\E07"; font-weight: bold; }
  .rui-issue-number-tb td:nth-of-type(8):before { content: "\E41\E1C\E19\E20\E32\E1E"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-receive-number-tb table, .rui-receive-number-tb thead, .rui-receive-number-tb tbody, .rui-receive-number-tb th, .rui-receive-number-tb td, .rui-receive-number-tb tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-receive-number-tb thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-receive-number-tb tr {
    margin: 0;
  }
    
  .rui-receive-number-tb tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-receive-number-tb td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-receive-number-tb td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-receive-number-tb td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-receive-number-tb td:nth-of-type(2):before { content: "\E17\E35\E48"; font-weight: bold; }
  .rui-receive-number-tb td:nth-of-type(3):before { content: "\E40\E2D\E01\E2A\E32\E23\E25\E07\E27\E31\E19\E17\E35\E48"; font-weight: bold; }
  .rui-receive-number-tb td:nth-of-type(4):before { content: "\E40\E23\E35\E22\E19"; font-weight: bold; }
  .rui-receive-number-tb td:nth-of-type(5):before { content: "\E40\E23\E37\E48\E2D\E07"; font-weight: bold; }
  .rui-receive-number-tb td:nth-of-type(6):before { content: "\E08\E32\E01"; font-weight: bold; }
  .rui-receive-number-tb td:nth-of-type(7):before { content: "\E16\E36\E07"; font-weight: bold; }
  .rui-receive-number-tb td:nth-of-type(8):before { content: "\E41\E1C\E19\E20\E32\E1E"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-positions-user-tb table, .rui-positions-user-tb thead, .rui-positions-user-tb tbody, .rui-positions-user-tb th, .rui-positions-user-tb td, .rui-positions-user-tb tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-positions-user-tb thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-positions-user-tb tr {
    margin: 0;
  }
    
  .rui-positions-user-tb tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-positions-user-tb td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-positions-user-tb td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-positions-user-tb td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-positions-user-tb td:nth-of-type(2):before { content: "\E40\E23\E35\E22\E19"; font-weight: bold; }
  .rui-positions-user-tb td:nth-of-type(3):before { content: "\E0A\E37\E48\E2D-\E19\E32\E21\E2A\E01\E38\E25"; font-weight: bold; }
  .rui-positions-user-tb td:nth-of-type(4):before { content: "\E15\E33\E41\E2B\E19\E48\E07"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-wf-action-dg-tb table, .rui-wf-action-dg-tb thead, .rui-wf-action-dg-tb tbody, .rui-wf-action-dg-tb th, .rui-wf-action-dg-tb td, .rui-wf-action-dg-tb tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-wf-action-dg-tb thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-wf-action-dg-tb tr {
    margin: 0;
  }
    
  .rui-wf-action-dg-tb tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-wf-action-dg-tb td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-wf-action-dg-tb td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-wf-action-dg-tb td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-wf-action-dg-tb td:nth-of-type(2):before { content: "\E40\E23\E35\E22\E19"; font-weight: bold; }
  .rui-wf-action-dg-tb td:nth-of-type(3):before { content: "\E0A\E37\E48\E2D-\E19\E32\E21\E2A\E01\E38\E25"; font-weight: bold; }
  .rui-wf-action-dg-tb td:nth-of-type(4):before { content: "\E15\E33\E41\E2B\E19\E48\E07"; font-weight: bold; }
}
.a4-paper #show_content_id .editing_content {
  text-align: justify;
}

@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-mydoc table, .rui-mydoc thead, .rui-mydoc tbody, .rui-mydoc th, .rui-mydoc td, .rui-mydoc tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-mydoc thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-mydoc tr {
    margin: 0;
  }
    
  .rui-mydoc tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-mydoc td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-mydoc td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-mydoc td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-mydoc td:nth-of-type(2):before { content: "\E23\E31\E1A\E2B\E19\E31\E07\E2A\E37\E2D"; font-weight: bold; }
  .rui-mydoc td:nth-of-type(3):before { content: "\E0A\E31\E49\E19\E04\E27\E32\E21\E40\E23\E47\E27"; font-weight: bold; }
  .rui-mydoc td:nth-of-type(4):before { content: "\E40\E25\E02\E17\E35\E48\E2B\E19\E31\E07\E2A\E37\E2D"; font-weight: bold; }
  .rui-mydoc td:nth-of-type(5):before { content: "\E08\E32\E01"; font-weight: bold; }
  .rui-mydoc td:nth-of-type(6):before { content: "\E23\E32\E22\E25\E30\E40\E2D\E35\E22\E14\E2B\E19\E31\E07\E2A\E37\E2D"; font-weight: bold; }
  .rui-mydoc td:nth-of-type(7):before { content: "\E1B\E23\E30\E40\E20\E17"; font-weight: bold; }
  .rui-mydoc td:nth-of-type(8):before { content: "\E2A\E16\E32\E19\E30"; font-weight: bold; }
  .rui-mydoc td:nth-of-type(9):before { content: "\E08\E31\E14\E01\E32\E23"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-operateddoc table, .rui-operateddoc thead, .rui-operateddoc tbody, .rui-operateddoc th, .rui-operateddoc td, .rui-operateddoc tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-operateddoc thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-operateddoc tr {
    margin: 0;
    /* margin: 0 0 1rem 0; */
  }
    
  .rui-operateddoc tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-operateddoc td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-operateddoc td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-operateddoc td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-operateddoc td:nth-of-type(2):before { content: "\E0A\E31\E49\E19\E04\E27\E32\E21\E40\E23\E47\E27"; font-weight: bold; }
  .rui-operateddoc td:nth-of-type(3):before { content: "\E40\E25\E02\E17\E35\E48\E2B\E19\E31\E07\E2A\E37\E2D"; font-weight: bold; }
  .rui-operateddoc td:nth-of-type(4):before { content: "\E08\E32\E01"; font-weight: bold; }
  .rui-operateddoc td:nth-of-type(5):before { content: "\E23\E32\E22\E25\E30\E40\E2D\E35\E22\E14\E2B\E19\E31\E07\E2A\E37\E2D"; font-weight: bold; }
  .rui-operateddoc td:nth-of-type(6):before { content: "\E1B\E23\E30\E40\E20\E17"; font-weight: bold; }
  .rui-operateddoc td:nth-of-type(7):before { content: "\E2A\E16\E32\E19\E30"; font-weight: bold; }
  .rui-operateddoc td:nth-of-type(8):before { content: "\E08\E31\E14\E01\E32\E23"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-draftdoc table, .rui-draftdoc thead, .rui-draftdoc tbody, .rui-draftdoc th, .rui-draftdoc td, .rui-draftdoc tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-draftdoc thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-draftdoc tr {
    margin: 0;
  }
    
  .rui-draftdoc tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-draftdoc td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-draftdoc td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-draftdoc td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-draftdoc td:nth-of-type(2):before { content: "\E40\E25\E02\E17\E35\E48"; font-weight: bold; }
  .rui-draftdoc td:nth-of-type(3):before { content: "\E25\E07\E27\E31\E19\E17\E35\E48"; font-weight: bold; }
  .rui-draftdoc td:nth-of-type(4):before { content: "\E40\E23\E35\E22\E19"; font-weight: bold; }
  .rui-draftdoc td:nth-of-type(5):before { content: "\E40\E23\E37\E48\E2D\E07"; font-weight: bold; }
  .rui-draftdoc td:nth-of-type(6):before { content: "\E08\E32\E01"; font-weight: bold; }
  .rui-draftdoc td:nth-of-type(7):before { content: "\E16\E36\E07"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-sendalldoc table, .rui-sendalldoc thead, .rui-sendalldoc tbody, .rui-sendalldoc th, .rui-sendalldoc td, .rui-sendalldoc tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-sendalldoc thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-sendalldoc tr {
    margin: 0;
  }
    
  .rui-sendalldoc tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-sendalldoc td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-sendalldoc td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-sendalldoc td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-sendalldoc td:nth-of-type(2):before { content: "\E40\E25\E02\E17\E30\E40\E1A\E35\E22\E19\E2A\E48\E07"; font-weight: bold; }
  .rui-sendalldoc td:nth-of-type(3):before { content: "\E17\E35\E48"; font-weight: bold; }
  .rui-sendalldoc td:nth-of-type(4):before { content: "\E25\E07\E27\E31\E19\E17\E35\E48"; font-weight: bold; }
  .rui-sendalldoc td:nth-of-type(5):before { content: "\E08\E32\E01"; font-weight: bold; }
  .rui-sendalldoc td:nth-of-type(6):before { content: "\E16\E36\E07"; font-weight: bold; }
  .rui-sendalldoc td:nth-of-type(7):before { content: "\E40\E23\E37\E48\E2D\E07"; font-weight: bold; }
  .rui-sendalldoc td:nth-of-type(8):before { content: "\E2A\E16\E32\E19\E30"; font-weight: bold; }
  .rui-sendalldoc td:nth-of-type(9):before { content: "\E08\E31\E14\E01\E32\E23"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-receivealldoc table, .rui-receivealldoc thead, .rui-receivealldoc tbody, .rui-receivealldoc th, .rui-receivealldoc td, .rui-receivealldoc tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-receivealldoc thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-receivealldoc tr {
    margin: 0;
  }
    
  .rui-receivealldoc tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-receivealldoc td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-receivealldoc td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-receivealldoc td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-receivealldoc td:nth-of-type(2):before { content: "\E40\E25\E02\E17\E30\E40\E1A\E35\E22\E19\E23\E31\E1A"; font-weight: bold; }
  .rui-receivealldoc td:nth-of-type(3):before { content: "\E17\E35\E48"; font-weight: bold; }
  .rui-receivealldoc td:nth-of-type(4):before { content: "\E25\E07\E27\E31\E19\E17\E35\E48"; font-weight: bold; }
  .rui-receivealldoc td:nth-of-type(5):before { content: "\E08\E32\E01"; font-weight: bold; }
  .rui-receivealldoc td:nth-of-type(6):before { content: "\E16\E36\E07"; font-weight: bold; }
  .rui-receivealldoc td:nth-of-type(7):before { content: "\E40\E23\E37\E48\E2D\E07"; font-weight: bold; }
  .rui-receivealldoc td:nth-of-type(8):before { content: "\E2A\E16\E32\E19\E30"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-orderdoc table, .rui-orderdoc thead, .rui-orderdoc tbody, .rui-orderdoc th, .rui-orderdoc td, .rui-orderdoc tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-orderdoc thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-orderdoc tr {
    margin: 0;
  }
    
  .rui-orderdoc tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-orderdoc td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-orderdoc td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-orderdoc td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-orderdoc td:nth-of-type(2):before { content: "\E40\E25\E02\E17\E35\E48"; font-weight: bold; }
  .rui-orderdoc td:nth-of-type(3):before { content: "\E40\E23\E37\E48\E2D\E07"; font-weight: bold; }
  .rui-orderdoc td:nth-of-type(4):before { content: "\E25\E07\E27\E31\E19\E17\E35\E48"; font-weight: bold; }
  .rui-orderdoc td:nth-of-type(5):before { content: "\E2D\E2D\E01\E42\E14\E22"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-searc-receive-documents table, .rui-searc-receive-documents thead, .rui-searc-receive-documents tbody, .rui-searc-receive-documents th, .rui-searc-receive-documents td, .rui-searc-receive-documents tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-searc-receive-documents thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-searc-receive-documents tr {
    margin: 0;
  }
    
  .rui-searc-receive-documents tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-searc-receive-documents td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-searc-receive-documents td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-searc-receive-documents td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-searc-receive-documents td:nth-of-type(2):before { content: "\E23\E31\E1A/\E2A\E48\E07"; font-weight: bold; }
  .rui-searc-receive-documents td:nth-of-type(3):before { content: "\E40\E25\E02\E17\E35\E48"; font-weight: bold; }
  .rui-searc-receive-documents td:nth-of-type(4):before { content: "\E17\E35\E48"; font-weight: bold; }
  .rui-searc-receive-documents td:nth-of-type(5):before { content: "\E25\E07\E27\E31\E19\E17\E35\E48"; font-weight: bold; }
  .rui-searc-receive-documents td:nth-of-type(6):before { content: "\E40\E23\E35\E22\E19"; font-weight: bold; }
  .rui-searc-receive-documents td:nth-of-type(7):before { content: "\E40\E23\E37\E48\E2D\E07"; font-weight: bold; }
  .rui-searc-receive-documents td:nth-of-type(8):before { content: "\E08\E32\E01"; font-weight: bold; }
  .rui-searc-receive-documents td:nth-of-type(9):before { content: "\E16\E36\E07"; font-weight: bold; }
  .rui-searc-receive-documents td:nth-of-type(10):before { content: "\E2B\E19\E48\E27\E22\E07\E32\E19\E17\E35\E48\E14\E33\E40\E19\E34\E19\E01\E32\E23"; font-weight: bold; }
  .rui-searc-receive-documents td:nth-of-type(11):before { content: "\E1B\E23\E30\E40\E20\E17\E40\E2D\E01\E2A\E32\E23"; font-weight: bold; }
  .rui-searc-receive-documents td:nth-of-type(12):before { content: "\E2A\E16\E32\E19\E30"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-search-send-documents table, .rui-search-send-documents thead, .rui-search-send-documents tbody, .rui-search-send-documents th, .rui-search-send-documents td, .rui-search-send-documents tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-search-send-documents thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-search-send-documents tr {
    margin: 0;
  }
    
  .rui-search-send-documents tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-search-send-documents td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-search-send-documents td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-search-send-documents td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-search-send-documents td:nth-of-type(2):before { content: "\E23\E31\E1A/\E2A\E48\E07"; font-weight: bold; }
  .rui-search-send-documents td:nth-of-type(3):before { content: "\E40\E25\E02\E17\E35\E48"; font-weight: bold; }
  .rui-search-send-documents td:nth-of-type(4):before { content: "\E17\E35\E48"; font-weight: bold; }
  .rui-search-send-documents td:nth-of-type(5):before { content: "\E25\E07\E27\E31\E19\E17\E35\E48"; font-weight: bold; }
  .rui-search-send-documents td:nth-of-type(6):before { content: "\E40\E23\E35\E22\E19"; font-weight: bold; }
  .rui-search-send-documents td:nth-of-type(7):before { content: "\E40\E23\E37\E48\E2D\E07"; font-weight: bold; }
  .rui-search-send-documents td:nth-of-type(8):before { content: "\E08\E32\E01"; font-weight: bold; }
  .rui-search-send-documents td:nth-of-type(9):before { content: "\E16\E36\E07"; font-weight: bold; }
  .rui-search-send-documents td:nth-of-type(10):before { content: "\E2B\E19\E48\E27\E22\E07\E32\E19\E17\E35\E48\E14\E33\E40\E19\E34\E19\E01\E32\E23"; font-weight: bold; }
  .rui-search-send-documents td:nth-of-type(11):before { content: "\E1B\E23\E30\E40\E20\E17\E40\E2D\E01\E2A\E32\E23"; font-weight: bold; }
  .rui-search-send-documents td:nth-of-type(12):before { content: "\E2A\E16\E32\E19\E30"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-cancelleddoc table, .rui-cancelleddoc thead, .rui-cancelleddoc tbody, .rui-cancelleddoc th, .rui-cancelleddoc td, .rui-cancelleddoc tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-cancelleddoc thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-cancelleddoc tr {
    margin: 0;
  }
    
  .rui-cancelleddoc tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-cancelleddoc td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-cancelleddoc td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-cancelleddoc td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-cancelleddoc td:nth-of-type(2):before { content: "\E17\E35\E48"; font-weight: bold; }
  .rui-cancelleddoc td:nth-of-type(3):before { content: "\E40\E2D\E01\E2A\E32\E23\E25\E07\E27\E31\E19\E17\E35\E48"; font-weight: bold; }
  .rui-cancelleddoc td:nth-of-type(4):before { content: "\E40\E23\E35\E22\E19"; font-weight: bold; }
  .rui-cancelleddoc td:nth-of-type(5):before { content: "\E40\E23\E37\E48\E2D\E07"; font-weight: bold; }
  .rui-cancelleddoc td:nth-of-type(6):before { content: "\E08\E32\E01"; font-weight: bold; }
  .rui-cancelleddoc td:nth-of-type(7):before { content: "\E16\E36\E07"; font-weight: bold; }
  .rui-cancelleddoc td:nth-of-type(8):before { content: "\E41\E1C\E19\E20\E32\E1E"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-storages table, .rui-storages thead, .rui-storages tbody, .rui-storages th, .rui-storages td, .rui-storages tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-storages thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-storages tr {
    margin: 0;
  }
    
  .rui-storages tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-storages td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-storages td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-storages td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-storages td:nth-of-type(2):before { content: "\E40\E25\E02\E17\E35\E48\E2B\E19\E31\E07\E2A\E37\E2D"; font-weight: bold; }
  .rui-storages td:nth-of-type(3):before { content: "\E40\E23\E37\E48\E2D\E07"; font-weight: bold; }
  .rui-storages td:nth-of-type(4):before { content: "\E08\E32\E01"; font-weight: bold; }
  .rui-storages td:nth-of-type(5):before { content: "\E40\E23\E35\E22\E19"; font-weight: bold; }
  .rui-storages td:nth-of-type(6):before { content: "\E2A\E16\E32\E19\E30"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-receive-report table, .rui-receive-report thead, .rui-receive-report tbody, .rui-receive-report th, .rui-receive-report td, .rui-receive-report tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-receive-report thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-receive-report tr {
    margin: 0;
  }
    
  .rui-receive-report tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-receive-report td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-receive-report td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-receive-report td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-receive-report td:nth-of-type(2):before { content: "\E40\E25\E02\E17\E35\E48\E40\E2D\E01\E2A\E32\E23"; font-weight: bold; }
  .rui-receive-report td:nth-of-type(3):before { content: "\E40\E25\E02\E17\E35\E48\E23\E31\E1A\E40\E2D\E01\E2A\E32\E23"; font-weight: bold; }
  .rui-receive-report td:nth-of-type(4):before { content: "\E08\E32\E01\E2B\E19\E48\E27\E22\E07\E32\E19"; font-weight: bold; }
  .rui-receive-report td:nth-of-type(5):before { content: "\E16\E36\E07\E2B\E19\E48\E27\E22\E07\E32\E19"; font-weight: bold; }
  .rui-receive-report td:nth-of-type(6):before { content: "\E27\E31\E19\E17\E35\E48\E23\E31\E1A\E40\E2D\E01\E2A\E32\E23"; font-weight: bold; }
  .rui-receive-report td:nth-of-type(7):before { content: "\E2B\E31\E27\E02\E49\E2D"; font-weight: bold; }
  .rui-receive-report td:nth-of-type(8):before { content: "\E2A\E16\E32\E19\E30"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-aware-report table, .rui-aware-report thead, .rui-aware-report tbody, .rui-aware-report th, .rui-aware-report td, .rui-aware-report tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-aware-report thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-aware-report tr {
    margin: 0;
  }
    
  .rui-aware-report tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-aware-report td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-aware-report td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-aware-report td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-aware-report td:nth-of-type(2):before { content: "\E40\E25\E02\E17\E35\E48\E40\E2D\E01\E2A\E32\E23"; font-weight: bold; }
  .rui-aware-report td:nth-of-type(3):before { content: "\E08\E32\E01\E2B\E19\E48\E27\E22\E07\E32\E19"; font-weight: bold; }
  .rui-aware-report td:nth-of-type(4):before { content: "\E16\E36\E07\E2B\E19\E48\E27\E22\E07\E32\E19"; font-weight: bold; }
  .rui-aware-report td:nth-of-type(5):before { content: "\E27\E31\E19\E17\E35\E48\E2A\E48\E07\E40\E2D\E01\E2A\E32\E23"; font-weight: bold; }
  .rui-aware-report td:nth-of-type(6):before { content: "\E2B\E31\E27\E02\E49\E2D"; font-weight: bold; }
  .rui-aware-report td:nth-of-type(7):before { content: "\E2A\E16\E32\E19\E30"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-internal-report table, .rui-internal-report thead, .rui-internal-report tbody, .rui-internal-report th, .rui-internal-report td, .rui-internal-report tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-internal-report thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-internal-report tr {
    margin: 0;
  }
    
  .rui-internal-report tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-internal-report td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-internal-report td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-internal-report td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-internal-report td:nth-of-type(2):before { content: "\E40\E25\E02\E17\E35\E48\E40\E2D\E01\E2A\E32\E23"; font-weight: bold; }
  .rui-internal-report td:nth-of-type(3):before { content: "\E08\E32\E01\E2B\E19\E48\E27\E22\E07\E32\E19"; font-weight: bold; }
  .rui-internal-report td:nth-of-type(4):before { content: "\E16\E36\E07\E2B\E19\E48\E27\E22\E07\E32\E19"; font-weight: bold; }
  .rui-internal-report td:nth-of-type(5):before { content: "\E27\E31\E19\E17\E35\E48\E2A\E48\E07\E40\E2D\E01\E2A\E32\E23"; font-weight: bold; }
  .rui-internal-report td:nth-of-type(6):before { content: "\E2B\E31\E27\E02\E49\E2D"; font-weight: bold; }
  .rui-internal-report td:nth-of-type(7):before { content: "\E2A\E16\E32\E19\E30"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-external-report table, .rui-external-report thead, .rui-external-report tbody, .rui-external-report th, .rui-external-report td, .rui-external-report tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-external-report thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-external-report tr {
    margin: 0;
  }
    
  .rui-external-report tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-external-report td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-external-report td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-external-report td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-external-report td:nth-of-type(2):before { content: "\E40\E25\E02\E17\E35\E48\E40\E2D\E01\E2A\E32\E23"; font-weight: bold; }
  .rui-external-report td:nth-of-type(3):before { content: "\E08\E32\E01\E2B\E19\E48\E27\E22\E07\E32\E19"; font-weight: bold; }
  .rui-external-report td:nth-of-type(4):before { content: "\E16\E36\E07\E2B\E19\E48\E27\E22\E07\E32\E19"; font-weight: bold; }
  .rui-external-report td:nth-of-type(5):before { content: "\E27\E31\E19\E17\E35\E48\E2A\E48\E07\E40\E2D\E01\E2A\E32\E23"; font-weight: bold; }
  .rui-external-report td:nth-of-type(6):before { content: "\E2B\E31\E27\E02\E49\E2D"; font-weight: bold; }
  .rui-external-report td:nth-of-type(7):before { content: "\E2A\E16\E32\E19\E30"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-usage-report table, .rui-usage-report thead, .rui-usage-report tbody, .rui-usage-report th, .rui-usage-report td, .rui-usage-report tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-usage-report thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-usage-report tr {
    margin: 0;
  }
    
  .rui-usage-report tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-usage-report td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-usage-report td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-usage-report td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-usage-report td:nth-of-type(2):before { content: "\E0A\E37\E48\E2D-\E2A\E01\E38\E25"; font-weight: bold; }
  .rui-usage-report td:nth-of-type(3):before { content: "\E15\E33\E41\E2B\E19\E48\E07"; font-weight: bold; }
  .rui-usage-report td:nth-of-type(4):before { content: "\E2B\E19\E48\E27\E22\E07\E32\E19"; font-weight: bold; }
  .rui-usage-report td:nth-of-type(5):before { content: "\E27\E31\E19\E40\E02\E49\E32\E23\E30\E1A\E1A"; font-weight: bold; }
  .rui-usage-report td:nth-of-type(6):before { content: "IP Address"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-statistic-report table, .rui-statistic-report thead, .rui-statistic-report tbody, .rui-statistic-report th, .rui-statistic-report td, .rui-statistic-report tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-statistic-report thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-statistic-report tr {
    margin: 0;
  }
    
  .rui-statistic-report tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-statistic-report td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-statistic-report td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-statistic-report td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-statistic-report td:nth-of-type(2):before { content: "\E0A\E37\E48\E2D-\E2A\E01\E38\E25"; font-weight: bold; }
  .rui-statistic-report td:nth-of-type(3):before { content: "\E15\E33\E41\E2B\E19\E48\E07"; font-weight: bold; }
  .rui-statistic-report td:nth-of-type(4):before { content: "\E2B\E19\E48\E27\E22\E07\E32\E19"; font-weight: bold; }
  .rui-statistic-report td:nth-of-type(5):before { content: "\E08\E33\E19\E27\E19\E40\E02\E49\E32\E43\E0A\E49\E23\E30\E1A\E1A"; font-weight: bold; }
}




@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-wait-record-receive-report table, .rui-wait-record-receive-report thead, .rui-wait-record-receive-report tbody, .rui-wait-record-receive-report th, .rui-wait-record-receive-report td, .rui-wait-record-receive-report tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-wait-record-receive-report thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-wait-record-receive-report tr {
    margin: 0;
  }
    
  .rui-wait-record-receive-report tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-wait-record-receive-report td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-wait-record-receive-report td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-wait-record-receive-report td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-wait-record-receive-report td:nth-of-type(2):before { content: "\E40\E25\E02\E17\E35\E48\E2B\E19\E31\E07\E2A\E37\E2D"; font-weight: bold; }
  .rui-wait-record-receive-report td:nth-of-type(3):before { content: "\E40\E23\E37\E48\E2D\E07"; font-weight: bold; }
  .rui-wait-record-receive-report td:nth-of-type(4):before { content: "\E08\E32\E01"; font-weight: bold; }
  .rui-wait-record-receive-report td:nth-of-type(5):before { content: "\E40\E23\E35\E22\E19"; font-weight: bold; }
  .rui-wait-record-receive-report td:nth-of-type(6):before { content: "\E1B\E23\E30\E40\E20\E17"; font-weight: bold; }
  .rui-wait-record-receive-report td:nth-of-type(7):before { content: "\E2A\E16\E32\E19\E30"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-inprogress-report table, .rui-inprogress-report thead, .rui-inprogress-report tbody, .rui-inprogress-report th, .rui-inprogress-report td, .rui-inprogress-report tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-inprogress-report thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-inprogress-report tr {
    margin: 0;
  }
    
  .rui-inprogress-report tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-inprogress-report td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-inprogress-report td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-inprogress-report td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-inprogress-report td:nth-of-type(2):before { content: "\E40\E25\E02\E17\E35\E48\E2B\E19\E31\E07\E2A\E37\E2D"; font-weight: bold; }
  .rui-inprogress-report td:nth-of-type(3):before { content: "\E40\E23\E37\E48\E2D\E07"; font-weight: bold; }
  .rui-inprogress-report td:nth-of-type(4):before { content: "\E08\E32\E01"; font-weight: bold; }
  .rui-inprogress-report td:nth-of-type(5):before { content: "\E40\E23\E35\E22\E19"; font-weight: bold; }
  .rui-inprogress-report td:nth-of-type(6):before { content: "\E1B\E23\E30\E40\E20\E17"; font-weight: bold; }
  .rui-inprogress-report td:nth-of-type(7):before { content: "\E2A\E16\E32\E19\E30"; font-weight: bold; }
}
.settings-page .card-box-shadow {
  box-shadow: 0 2px 6px 0 lightgray;
}

.settings-page .darkgreen-border-top {
  border-top: 6px solid darkgreen;
}

.settings-page .darkblue-border-top {
  border-top: 6px solid darkblue;
}

.settings-page .darkred-border-top {
  border-top: 6px solid darkred;
}

.settings-page .darkgoldenrod-border-top {
  border-top: 6px solid darkgoldenrod;
}

.settings-page .darkcyan-border-top {
  border-top: 6px solid darkcyan;
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-acting-setting table, .rui-acting-setting thead, .rui-acting-setting tbody, .rui-acting-setting th, .rui-acting-setting td, .rui-acting-setting tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-acting-setting thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-acting-setting tr {
    margin: 0;
  }
    
  .rui-acting-setting tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-acting-setting td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-acting-setting td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-acting-setting td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-acting-setting td:nth-of-type(2):before { content: "\E0A\E37\E48\E2D   - \E19\E32\E21\E2A\E01\E38\E25"; font-weight: bold; }
  .rui-acting-setting td:nth-of-type(3):before { content: "\E44\E1B"; font-weight: bold; }
  .rui-acting-setting td:nth-of-type(4):before { content: "\E01\E25\E31\E1A"; font-weight: bold; }
  .rui-acting-setting td:nth-of-type(5):before { content: "\E1C\E39\E49\E23\E31\E01\E29\E32\E23\E32\E0A\E01\E32\E23\E41\E17\E19"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-signature-setting table, .rui-signature-setting thead, .rui-signature-setting tbody, .rui-signature-setting th, .rui-signature-setting td, .rui-signature-setting tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-signature-setting thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-signature-setting tr {
    margin: 0;
  }
    
  .rui-signature-setting tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-signature-setting td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-signature-setting td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-signature-setting td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-signature-setting td:nth-of-type(2):before { content: "\E0A\E37\E48\E2D-\E19\E32\E21\E2A\E01\E38\E25"; font-weight: bold; }
  .rui-signature-setting td:nth-of-type(3):before { content: "\E15\E33\E41\E2B\E19\E48\E07"; font-weight: bold; }
  .rui-signature-setting td:nth-of-type(4):before { content: "\E2A\E32\E02\E32"; font-weight: bold; }
  .rui-signature-setting td:nth-of-type(5):before { content: "\E25\E32\E22\E21\E37\E2D\E0A\E37\E48\E2D"; font-weight: bold; }
  .rui-signature-setting td:nth-of-type(6):before { content: ""; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-system-notification-setting table, 
  .rui-system-notification-setting thead, 
  .rui-system-notification-setting tbody, 
  .rui-system-notification-setting th, 
  .rui-system-notification-setting td, 
  .rui-system-notification-setting tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-system-notification-setting thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-system-notification-setting tr {
    margin: 0;
  }
    
  .rui-system-notification-setting tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-system-notification-setting td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-system-notification-setting td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-system-notification-setting td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-system-notification-setting td:nth-of-type(2):before { content: "\E02\E49\E2D\E04\E27\E32\E21\E41\E08\E49\E07\E40\E15\E37\E2D\E19"; font-weight: bold; }
  .rui-system-notification-setting td:nth-of-type(3):before { content: "\E27\E31\E19\E40\E27\E25\E32\E17\E35\E48\E41\E08\E49\E07\E40\E15\E37\E2D\E19"; font-weight: bold; }
}
.loading-spin {
	-webkit-animation: spin 4s linear infinite;
	        animation: spin 4s linear infinite;
}

@-webkit-keyframes spin {
	0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
	0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
#SettingUserPage .nav.nav-tabs {
    float: left;
    display: block;
    margin-right: 20px;
    border-bottom:0;
    border-right: 1px solid #ddd;
    padding-right: 15px;
}

#SettingUserPage li.active {
	font-weight: bold;
}

#SettingUserPage .nav-tabs li:not(.active):hover {
	font-weight: 500;
	color: gray;
}
#DocumentTemplatePage .document-card {
	font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
    color: #354368;
    height: 200px;
    padding-top: 60px;
    cursor: pointer;
    box-shadow: -7px 7px 10px 2px rgba(0, 0, 0, .3);
    text-shadow: -3px 3px 6px #858585;
}

#DocumentTemplatePage .note {
    position: relative;
    background-color: #f1f1f1;
    background-repeat: no-repeat;
    background-position-y: 20px;
    background-position-x: 10px;
    background-size: 35px;
    -webkit-background-size: 35px;
    -moz-background-size: 35px;
    -o-background-size: 35px;
    overflow: hidden;
}

#DocumentTemplatePage .note:before {
	content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 16px 16px 0;
    border-style: solid;
    border-color: #fff #fff rgba(0,0,0,0.2) rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.2);
    box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
    display: block;
    width: 0;
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-dp table, .rui-dp thead, .rui-dp tbody, .rui-dp th, .rui-dp td, .rui-dp tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-dp thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-dp tr {
    margin: 0;
  }
    
  .rui-dp tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-dp td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-dp td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-dp td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-dp td:nth-of-type(2):before { content: "\E0A\E37\E48\E2D\E01\E25\E38\E48\E21"; font-weight: bold; }
  .rui-dp td:nth-of-type(3):before { content: "\E04\E19\E20\E32\E22\E43\E19\E01\E25\E38\E48\E21"; font-weight: bold; }
  .rui-dp td:nth-of-type(4):before { content: "\E08\E33\E19\E27\E19(\E04\E19)"; font-weight: bold; }
}
#department-groups .react-bs-table table td, .react-bs-table table th {
  white-space: normal;
}

@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-department-group-setting table, .rui-department-group-setting thead, .rui-department-group-setting tbody, .rui-department-group-setting th, .rui-department-group-setting td, .rui-department-group-setting tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-department-group-setting thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-department-group-setting tr {
    margin: 0;
  }
    
  .rui-department-group-setting tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-department-group-setting td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-department-group-setting td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-department-group-setting td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-department-group-setting td:nth-of-type(2):before { content: "\E0A\E37\E48\E2D\E01\E25\E38\E48\E21"; font-weight: bold; }
  .rui-department-group-setting td:nth-of-type(3):before { content: "\E04\E19\E20\E32\E22\E43\E19\E01\E25\E38\E48\E21"; font-weight: bold; }
  .rui-department-group-setting td:nth-of-type(4):before { content: "\E08\E33\E19\E27\E19(\E04\E19)"; font-weight: bold; }
  .rui-department-group-setting td:nth-of-type(5):before { content: ""; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-dpg-table table, .rui-dpg-table thead, .rui-dpg-table tbody, .rui-dpg-table th, .rui-dpg-table td, .rui-dpg-table tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-dpg-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-dpg-table tr {
    margin: 0;
  }
    
  .rui-dpg-table tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-dpg-table td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-dpg-table td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-dpg-table td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-dpg-table td:nth-of-type(2):before { content: "\E0A\E37\E48\E2D\E01\E25\E38\E48\E21"; font-weight: bold; }
  .rui-dpg-table td:nth-of-type(3):before { content: "\E04\E19\E20\E32\E22\E43\E19\E01\E25\E38\E48\E21"; font-weight: bold; }
  .rui-dpg-table td:nth-of-type(4):before { content: "\E08\E33\E19\E27\E19(\E04\E19)"; font-weight: bold; }
}
@media only screen and (max-width: 991px) {

  /* Force table to not be like tables anymore */
  .rui-dpg-table table, .rui-dpg-table thead, .rui-dpg-table tbody, .rui-dpg-table th, .rui-dpg-table td, .rui-dpg-table tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rui-dpg-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rui-dpg-table tr {
    margin: 0;
  }
    
  .rui-dpg-table tr:nth-child(odd) {
    background: #ccc;
  }
  
  .rui-dpg-table td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40% !important;
    min-height: 30px;
  }

  .rui-dpg-table td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 40%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 30px;
  }

  /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .rui-dpg-table td:nth-of-type(1):before { content: ""; font-weight: bold; }
  .rui-dpg-table td:nth-of-type(2):before { content: "\E0A\E37\E48\E2D\E01\E25\E38\E48\E21"; font-weight: bold; }
  .rui-dpg-table td:nth-of-type(3):before { content: "\E04\E19\E20\E32\E22\E43\E19\E01\E25\E38\E48\E21"; font-weight: bold; }
  .rui-dpg-table td:nth-of-type(4):before { content: "\E08\E33\E19\E27\E19(\E04\E19)"; font-weight: bold; }
}
.forum-content {
  white-space: pre-wrap;
  -moz-tab-size: 16;
       tab-size: 16;
}

.forum-content p {
  padding-top: 6pt;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

ol, ul {
  margin-left: 1.5rem;
  margin-bottom: 0;
}

.forums a {
  color: #343a40 !important;
}
html, body {
	width: 100%;
	height: 100%;
	background-color: #abe8c8;
	font-size: 1.15rem;
	font-family: "THSarabunNew";
	line-height: 1;
	font-weight: 800;
}

#root {
	height: 100%;
}

#root>div {
	height: 100%;
}

h1, .h1 {
	font-size: 2.4rem;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	font-weight: 800;
}

input {
	font-weight: 800;
}

input.form-control, select.form-control {
	height: 1.6rem;
}

#AppLayout {
	overflow-x: hidden;
	box-sizing: border-box;
}

.cursor-pointer{
  cursor: pointer;
}

.inbox-body {
	/*min-height: 550px;*/
	min-height: 78vh;
}

.react-datepicker-popper {
	z-index: 9;
}

.nav-link {
	padding: 0.35rem 0.25rem;
}

.nav > .nav-item {
	border-bottom: 0.5px;
	border-bottom-color: lightgray; 
	border-bottom-style: dashed;
}

.nav > .nav-item > .nav-link {
	color: inherit;
}

.nav > .nav-item > .nav-link:hover {
	font-weight: bold;
	text-decoration: underline;
}

.nav-pills .nav-link.active, .show > .nav-pills .nav-link {
	color: inherit;
	font-weight: bold;
	background-color: #9BD3EE;
	text-decoration: underline;
}

.text_underline {
	text-decoration: underline;
}

.edoc-logo-sm {
	width: 100%;
	height: auto;
}

.field-label {
	margin-bottom: 0;
}

.required::after {
  content: ' * ';
  color: red;
}

.pointer {
	cursor: pointer;;
}

.nav > .nav-item {
	border-bottom: 1px dashed lightgray;
}

.modal-sm {
	max-width: 600px !important;
}

.a4-paper {
	width: 210mm;
	height: 297mm;
	padding-top: 25mm;
	padding-left: 30mm;
	padding-right: 20mm;
	font-size: 16pt;
	position: relative;
	margin: 0 auto;
}

.footer-page {
	position: absolute;
  bottom: 20mm;
  border: 1px dashed orange;
  width: 77%;
  left: 30mm;
}

.a4-vertical-padding {
	padding: 3rem;
}

.delete-document-page-btn {
	position: absolute;
	top: 2mm;
	right: 24mm;
}

.emblem-center {
	width: 14%;
	position: absolute;
	left: 43%;
}

.garuda-emblem-logo {
	height: 80px;
}

.green-icon {
	color: green;
}

.green-text {
	color: green;
}

.red-icon {
	color: #cc0000;
}

.blue-icon {
	color: cadetblue;
}

.gray-icon {
	color: gray;
}

.gray-icon:hover {
	color: #222;
}

.black-icon {
	color: black;
}

.text-black {
	color: black;
}

.text-black:hover {
	color: black;
}

.focus-item {
	background: lightblue;
}

.group-list {
	line-height: 30px;
}

.group-list:hover {
	background: lightblue;
}

.group-content {
	height: 100vh;
	overflow-y: scroll; 
}

.children-content {
	height: 380px;
	overflow-y: scroll; 
}

.react-bs-select-all {
	display: none;
}

#organize-header {
	height: 50px;
}

.ul-group-list {
	list-style-type: none;
	padding-left: 20px;
}

.react-bs-table-no-data {
	color: gray;
}

.nav.nav-tabs .nav-item .nav-link {
	color: #08c;
}

.nav.nav-tabs .nav-item .nav-link.active {
	color: gray;
}

.nav.nav-tabs {
	margin-left: 0;
}

.top-right {
	position: relative;
	float: right;
}

.inbox {
	font-size: 1.1rem;
}

.small {
	font-size: 1.2rem;
}

.xs-small {
	font-size: 1rem;
}

.xxs-small {
	font-size: 0.9rem;
}

.btn {
  padding: .4rem .5rem .25rem;
  font-size: 1.2rem;
  line-height: 0.8;
}

hr {
	margin: 0;
}

.form-control {
	padding: 1px 0.5rem 1px 0.5rem;
	line-height: 1;
	font-size: 1.15rem;
}

.fa {
	font-size: 0.85rem;
}

.fa-2x {
	font-size: 1rem;
}

.mini-icon {
	font-size: 0.3rem;
}

.small-icon {
	font-size: 0.6rem;
}

select.form-control.form-control-sm {
  font-family: Sans-Serif;
	font-size: 0.8rem;
}

.text-overflow {
  	overflow:hidden;
  	display:inline-block;
  	text-overflow: ellipsis;
  	white-space: nowrap;
}

.small-btn {
	font-size: 1rem;
    line-height: 0.5;
}

.popover-header {
	font-size: 16px;
}

.popover-body {
	font-size: 14px;
}

p {
	margin-bottom: 0;
	line-height: 1.1;
}

.ex-small-text {
	font-size: 14px;
}

.purple-text {
	color: #57E;
}

.orange-text {
	color: #F90;
}

.list-style-none {
	list-style-type: none;
}

.list-thai-style {
	list-style-type: thai;
}

.green-border {
	border: 1px solid green;
}

.popover-list > li {
	background-color: #eee; 
	font-size: 0.8rem;
	list-style-type: none;
	cursor: pointer;
}

.popover-list > li:hover {
	background-color: rgba(128,201,255,.7);
}

.close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.position-relative {
	position: relative;
}

table tr.pointer {
  cursor: pointer;
}

.page-link {
	line-height: 0.7;
}

.loading-color {
	color: #08c;
}

.lightgray_hover:hover {
    background-color: lightgray !important;
}

.overflow-visible {
	overflow: visible !important;
	white-space: normal !important;
}

.urgent {
	background-color: #FADBD8 !important;
}

.very-hurry {
	background-color: #FAE5D3 !important;
}

.hurry {
	background-color: #FEF5E7 !important;
}

.speed-level-hurry.active, .secret-level-confidential.active {
	background-color: #FEF5E7 !important;
	border-color: #FEF5E7 !important;
	color: #212529 !important;
}

.speed-level-very_hurry.active, .secret-level-secret.active {
	background-color: #FAE5D3 !important;
	border-color: #FAE5D3 !important;
	color: #212529 !important;
}

.speed-level-urgent.active, .secret-level-top_secret.active {
	background-color: #FADBD8 !important;
	border-color: #FADBD8 !important;
	color: #212529 !important;
}

.text-hurry, .text-very_hurry, .text-urgent {
	color: red;
	font-weight: bold;
	font-size: 1.1rem;
}

.unread {
	background-color: #FFFACD !important;
}

.error_message_navigation {
	height: 70px;
}

.react-bs-table {
	font-size: 1.1rem;
}

.label-control {
	font-weight: bold;
	margin-bottom: 0;
}

.Select-placeholder, .Select--single > .Select-control .Select-value {
	line-height: 44px;
}

legend {
	width: auto;
}

.bg-lightblue {
	background: #5e9ac6;
}

.text-md {
	font-size: 16pt;
}

.ml-minus-15 {
	margin-left: -15px;
}

.ml-minus-20 {
	margin-left: -20px;
}

.ml-minus-25 {
	margin-left: -25px;
}

.ml-minus-30 {
	margin-left: -30px;
}

.ml-minus-35 {
	margin-left: -35px;
}

.text_underline_dashed {
	line-height: 1.1;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAXCAYAAAARIY8tAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABWWlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS40LjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx0aWZmOk9yaWVudGF0aW9uPjE8L3RpZmY6T3JpZW50YXRpb24+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgpMwidZAAAAQ0lEQVRIDWP8DwQMNARMNDQbbPSoBQRDeDSIRoOIYAgQVDCaikaDiGAIEFQwmopGQBAxAv0Ib1UgNzAYGUFSEECJOAASRRElNPd/3AAAAABJRU5ErkJggg==);
}

.react-bs-table input[type='checkbox']:disabled {
	display: none;
}

.react-bs-table-search-form input {
	height: 35px;
}

textarea.form-control {
	padding: 0.5rem;
}

.speed_level-list > label, .secret_level-list > label {
	width: 100px;
}

.btn-group-vertical>.btn.active, 
.btn-group-vertical>.btn:active, 
.btn-group-vertical>.btn:focus, 
.btn-group>.btn.active, 
.btn-group>.btn:active, 
.btn-group>.btn:focus {
	z-index: 0;
}

ul.ul-target-list {
	margin: 0;
	padding: 0 0 0 1rem;
}

.table-bordered tr, .table-bordered td, .table-bordered th, .table-bordered thead th {
	border: 1px solid darkgray;
}

.indent {
	padding-left: 2rem;
}

/* react autosuggest*/
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 30px;
  padding: 10px 10px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

@media only screen and (min-width: 0) { /* xs 576 - 767 */
  .navbar-collapse .navbar-nav .nav-item a {
    border-bottom: 1px dashed gray;
  }

  .sub-sidebar-menu .nav .nav-item .nav-link {
    color: lightslategrey;
  }

  .sub-sidebar-menu > .nav > .nav-item {
    border-bottom: 0;
  }

  .sub-sidebar-menu > .nav > .nav-item > a {
    border-bottom: 0;
  }

  .col-form-label {
    line-height: 1;
  }

  textarea {
    border: 1px solid lightgray;
  }
}

@media only screen and (min-width: 576px) { /* sm 576 - 767 */
  .top-header-height {
    height: '10vh';
  }
}

@media only screen and (min-width: 768px) { /* md 768 - 991 */
}

@media only screen and (min-width: 992px) { /* lg 992 - xxxx */
  .navbar-collapse .navbar-nav .nav-item a {
    border-bottom: 0;
  }

  .documents-sidebar, .reports-sidebar, .settings-sidebar, .department_groups_settings-sidebar {
    min-height: 500px;
  }

  .sub-sidebar-menu .nav .nav-item .nav-link {
    color: inherit;
  }

  .sub-sidebar-menu > .nav > .nav-item {
    border-bottom: 1px dashed lightgray;
  }
}
.ql-container {
  font-family: "THSarabunNew";
}

.quill, #toolbar, .raw-html {
  margin-left: -15px;
  margin-right: -15px;
}

#editing_attachment_id .quill {
  margin-left: -15px;
  margin-right: -15px;
}

.quill p, .editing_content p, .editing_content li {
  white-space: pre-wrap;
  line-height: 1.1;
}

.ql-editor, .editing_content {
  tab-size: 20;
  -moz-tab-size: 20;
  -o-tab-size:  20;
}

.ql-editor {
  font-size: 16pt;
}

.ql-editor p:not(:first-child), 
.ql-editor ol:not(:first-child), 
.ql-editor ul:not(:first-child), 
.ql-editor pre:not(:first-child), 
.ql-editor blockquote:not(:first-child), 
.ql-editor h1:not(:first-child), 
.ql-editor h2:not(:first-child), 
.ql-editor h3:not(:first-child), 
.ql-editor h4:not(:first-child), 
.ql-editor h5:not(:first-child), 
.ql-editor h6:not(:first-child) {
  padding-top: 6pt;
}

.editing_content p:not(:first-child), 
.editing_content ol:not(:first-child), 
.editing_content ul:not(:first-child), 
.editing_content pre:not(:first-child), 
.editing_content blockquote:not(:first-child), 
.editing_content h1:not(:first-child), 
.editing_content h2:not(:first-child), 
.editing_content h3:not(:first-child), 
.editing_content h4:not(:first-child), 
.editing_content h5:not(:first-child), 
.editing_content h6:not(:first-child) {
  padding-top: 6pt;
}

.editing_content ol {
  margin-bottom: 0;
}

.attached_things_label p, .suggest_text_label p {
  padding-top: 0;
}

.raw-editor {
  border-top: 0;
}

.raw-editor { display: none; width: 100%; }
.showRaw .ql-editor  { display: none; }
.showRaw .raw-editor { display: block; }

#toolbar .ql-html {
  font-size: 15px;
  font-weight: normal;
  width: 60px;
}


#secret {
  background-color: #eeb9b9; 
}

#secret .navbar-toggler {
  background-color: black; 
}

#secret a {
  color: #007bff;
}

#secret .text-link {
  color: #007bff;
}

#secret a.btn-danger {
  color: white;
}

#secret a.btn.switch-service-button {
  background-color: #abe8c8; 
  border-color: #abe8c8; 
  color: #495057;
}

#secret .header__top {
  background-color: #eeb9b9; 
}

#secret .header__top.navbar-dark .navbar-nav .nav-link {
  color: #808080;
}

#secret .header__top.navbar-dark .navbar-nav .nav-link.active {
  color: #000000;
  font-weight: 600;
  text-decoration: underline;
}

#secret .nav.nav-tabs .nav-item .nav-link {
  color: #808080;
}

#secret .nav.nav-tabs .nav-item .nav-link.active {
  font-weight: bold;
  color: #007bff;
}

#secret .nav-pills .nav-link.active, 
#secret .show > .nav-pills .nav-link {
  background-color: gray;
  border-radius: 0;
  color: #ffffff;
}

#secret .btn-success {
  background-color: #fe6227;
  border: #fe6227;
  color: #ffffff;
  border-radius: 0;
}

#secret .btn {
  border-radius: 0;
  color: #ffffff;
}

#secret .btn-link {
  border-radius: 0;
  color: #007bff;
}

#secret .page-link {
  color: #000 !important;
  border: 1px solid #aaa;
}

.next-number {
  color: red;
  font-size: 34px;
  font-weight: bold;
  background-color: yellow;
  padding: 0 15px;
}

.wait-for-receive {
  color: red;
  font-weight: bold;
}

.btn-notice {  
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
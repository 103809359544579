@media only screen and (min-width: 0) { /* xs 576 - 767 */
  .navbar-collapse .navbar-nav .nav-item a {
    border-bottom: 1px dashed gray;
  }

  .sub-sidebar-menu .nav .nav-item .nav-link {
    color: lightslategrey;
  }

  .sub-sidebar-menu > .nav > .nav-item {
    border-bottom: 0;
  }

  .sub-sidebar-menu > .nav > .nav-item > a {
    border-bottom: 0;
  }

  .col-form-label {
    line-height: 1;
  }

  textarea {
    border: 1px solid lightgray;
  }
}

@media only screen and (min-width: 576px) { /* sm 576 - 767 */
  .top-header-height {
    height: '10vh';
  }
}

@media only screen and (min-width: 768px) { /* md 768 - 991 */
}

@media only screen and (min-width: 992px) { /* lg 992 - xxxx */
  .navbar-collapse .navbar-nav .nav-item a {
    border-bottom: 0;
  }

  .documents-sidebar, .reports-sidebar, .settings-sidebar, .department_groups_settings-sidebar {
    min-height: 500px;
  }

  .sub-sidebar-menu .nav .nav-item .nav-link {
    color: inherit;
  }

  .sub-sidebar-menu > .nav > .nav-item {
    border-bottom: 1px dashed lightgray;
  }
}